import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import BackgroundSlider from "gatsby-image-background-slider"

const SliderLayout = props => {
  return (
    <BackgroundSlider 
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
              nodes {
                relativePath
                childImageSharp {
                  fluid (maxWidth: 2560, quality: 75){
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        `)}
        initDelay={4}   // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={2}  // transition duration between images
        duration={4}    // how long an image is shown
        // specify images to include (and their order) according to `relativePath`
        images={props.images}

        // pass down standard element props
        style={{
          width: props.width,
          height: props.height,
          top: props.top,
          position: "absolute",
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}           
      > 
    </BackgroundSlider>
  )
}

export default SliderLayout