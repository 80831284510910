import React from "react"
import MediaQuery from "react-responsive"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SliderLayout from "../components/sliderLayout"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Livio Meyer Fotografie"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`Livio`, `Meyer`, `Fotografie`, `photography`, `blog`, `Luzern`, `Schweiz`, `Lucerne`, `Switzerland`]}
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Livio Meyer Fotografie</title>
          <link rel="canonical" href="http://livio-meyer-fotografie.ch" />
          <html lang="de" />
        </Helmet>
        <MediaQuery minWidth={1224}>
          <SliderLayout width={`100%`} height={`100%`} top={`0%`} images={[`1.jpg`, `2.jpg`, `3.jpg`, `4.jpg`, `5.jpg`, `6.jpg`]}/>
        </MediaQuery>
        <MediaQuery maxWidth={1223}>
          <SliderLayout width={`100vw`} height={`100vh`} top={`0%`} images={[`1_small.jpg`, `2_small.jpg`, `3_small.jpg`, `4_small.jpg`, `5_small.jpg`, `6_small.jpg`]}/>
        </MediaQuery>
      </Layout>
    )
  }
}

export default IndexPage
